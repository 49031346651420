//import firebase from "firebase/compat/app";
import { NewsObj } from "./newsData.js";
//import { onBeforeUnmount } from "vue";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";

import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadString,
  //uploadBytes,
  //  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import {
  doc,
  updateDoc,
  getFirestore,
  collection,
  addDoc,
  getDocs,
  limit,
  orderBy,
  query,
  //  runTransaction,
} from "firebase/firestore";

import "firebase/compat/auth";
import store from "./store";
import appRouter from "./router";

console.log("Repository v1");
// init
const firebaseConfig = {
  apiKey: "AIzaSyB-0eEuhuFA4ePgTAxqxzO54KaG0omUSS0",
  authDomain: "vpress-admin-dev.firebaseapp.com",
  projectId: "vpress-admin-dev",
  storageBucket: "vpress-admin-dev.appspot.com",
  messagingSenderId: "370073973892",
  appId: "1:370073973892:web:a9cc5f7e202cd48e487ed1",
};

// setup firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const coNEWS = "news";
const newsDB = collection(db, coNEWS);
const storage = getStorage(firebaseApp);
export const authListener = onAuthStateChanged(auth, function (user) {
  console.log(user.email);
});

/*
onBeforeUnmount(() => {
  authListener();
});
*/
// AUTH ---------------------------------------------------------------------------------------------------
export function authInit() {
  setPersistence(auth, browserSessionPersistence);
}
// -------------------------------------------
export function onAuthByEmail(mail, passwd) {
  if (onAuthTest()) {
    onAuthLogout();
  }
  console.log("onAuthChanged");
  signInWithEmailAndPassword(auth, mail, passwd)
    .then(
      // 成功時の処理
      (data) => {
        console.log("login succeeded.");
        console.log(data);
        console.log(data.user.email);
        store.commit("onAuthChanged", data.user.email, data.user.accessToken);
        //appRouter.replace("/news");
        appRouter.replace("/magazines");
      }
    )
    .catch(
      // エラー時の処理
      (error) => {
        console.log(error);
        store.commit("onLogout");
      }
    );
}

//--------------------------
export function onAuthLogout() {
  auth.signOut().then(() => {
    store.commit("onLogout");
    appRouter.replace("login");
  });
}

//--------------------------
export function onAuthTest() {
  var user = auth.currentUser;
  console.log(user);
  if (!user) return false;
  return user.accessToken != "";
}

// NEWS (V1) ----------------------------------------------------------------------------------
// create ----------------
export async function onNewsCreate() {
  if (!onAuthTest()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // store は try catch の外！
  try {
    var newsObj = new NewsObj();
    newsObj.auther = store.state.mail;
    const newsRef = await addDoc(newsDB, JSON.parse(JSON.stringify(newsObj)));
    console.log("Document written with ID: ", newsRef.id);
    newsObj.id = newsRef.id;
    onNewsUpdateDB(newsObj);
    //onNewsGet();
  } catch (e) {
    alert("add data error");
    console.error("Error adding document: ", e);
    return false, "store";
  }
  store.commit("onNewsAdd", newsObj);
  return true, "add ok";
}

export async function onDownload(id, imageOut) {
  console.log(id);
  var obj = null;
  for (var idx in store.state.newsAry) {
    if (store.state.newsAry[idx].id == id) {
      obj = store.state.newsAry[idx];
      break;
    }
  }
  if (obj == null) {
    alert("no news obj = " + id);
    return;
  }
  console.log(obj);
  // ------------------
  var newsText = "";
  var memoText = "";
  var picsText = "";
  var newsJson = JSON.stringify(obj);
  console.log(newsJson);

  // create zip -------------------------------------------
  function filename2str(fn) {
    var body = fn.split(".")[0];
    var dd = body.split("_");
    console.log(dd);
    var res = body + "," + dd[1];
    return res;
  }

  function urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  // images
  var zip = new JSZip();
  var files = [];
  console.log(obj.images);
  for (var nn in obj.images) {
    files.push(obj.images[nn].name);
    if (imageOut) {
      // zip image files
      if (!obj.images[nn].deleted) {
        zip.file(
          "_pic/" + obj.images[nn].name,
          urlToPromise(obj.images[nn].url),
          {
            binary: true,
          }
        );
      }
    }
  }

  //  create text;
  var lc = 0;
  for (var n in obj.nodes) {
    newsText = newsText + obj.nodes[n].text + "\n";
    var str = memoText + obj.nodes[n].lines[0].text + "\n";
    var strs = str.split("##");
    memoText = memoText + (parseInt(n) + 1) + ")" + strs[1];
    memoText = memoText + obj.nodes[n].memo + "\n";
    for (var ll in obj.nodes[n].lines) {
      var pn = obj.nodes[n].lines[ll].pic;
      if (pn >= 0) {
        picsText = picsText + lc + "," + filename2str(files[pn]) + "\n";
      }
      lc++;
    }
  }
  newsText = newsText.replace(/^\s*[\r\n]/gm, "");
  memoText = memoText.replace(/^\s*[\r\n]/gm, "");

  //var text = "zipファイルに含める文字列\nテキストです";
  zip.file("newsExportJson.txt", newsJson);
  zip.file("news.txt", newsText);
  zip.file("memo.txt", memoText);
  zip.file("picDict.txt", picsText);

  // generate download event
  zip.generateAsync({ type: "blob" }).then(function (content) {
    //BlobからオブジェクトURLを作成する
    const url = (window.URL || window.webkitURL).createObjectURL(content);
    //ダウンロード用にリンクを作成する
    const download = document.createElement("a");
    //リンク先に上記で生成したURLを指定する
    download.href = url;
    //download属性にファイル名を指定する
    download.download = obj.date + ".zip";
    //作成したリンクをクリックしてダウンロードを実行する
    download.click();
    //createObjectURLで作成したオブジェクトURLを開放する
    (window.URL || window.webkitURL).revokeObjectURL(url);
  });
}

// News List ---------------------------
export async function onNewsGet() {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onNewsReset");

  const qq = query(newsDB, orderBy("date", "desc"), limit(100));
  const querySnapshot = await getDocs(qq);
  querySnapshot.forEach((_doc) => {
    var obj = _doc.data();
    //obj.id = _doc.id;
    //console.log(obj);
    var news = new NewsObj();
    news.load(obj);
    store.commit("onNewsLoad", news);
  });
}
// News update----------------
export async function onNewsUpdateDB(data) {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  await updateDoc(doc(newsDB, data.id), JSON.parse(JSON.stringify(data)));
}

// News Memo update----------------
export async function onNewsMemoUpdate(memo) {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onNewsMemoUpdate", memo);
  onNewsUpdateDB(store.state.curNews);
}

// News Date update----------------
export async function onNewsDateUpdate(date) {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onNewsDateUpdate", date);
  onNewsUpdateDB(store.state.curNews);
}

// FILE ----------------------------------------------------------------------------------
export async function onFileCreate(file, newsId) {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  console.log("onFileCreate");
  console.log("upload to = images/" + newsId);
  const imageRef = ref(storage, "images/" + newsId + "/" + file.fileName);
  await uploadString(imageRef, file.fileData.split(",")[1], "base64")
    .then((snapshot) => {
      console.log("File metadata:", snapshot.metadata);
      getDownloadURL(snapshot.ref).then((url) => {
        console.log("File available at", url);
        //store.dispatch("registerImage", url, snapshot.metadata.name);
        var name = snapshot.metadata.name;
        store.commit("onNewsAddImage", { url, name });
        onNewsUpdateDB(store.state.curNews);
      });
    })
    .catch((error) => {
      console.error("Upload failed", error);
      return "";
    });
}

export async function onDeleteImage(imageId) {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  const name = store.state.curNews.getImageName(imageId);

  const imageRef = ref(
    storage,
    "images/" + store.state.curNews.id + "/" + name
  );
  // Delete the file
  deleteObject(imageRef)
    .then(() => {
      store.commit("onNewsDelImage", imageId);
      // File deleted successfully
      onNewsUpdateDB(store.state.curNews);
      alert("image deleted.");
    })
    .catch((error) => {
      if (error.code.indexOf("object-not-found") >= 0) {
        store.commit("onNewsDelImage", imageId);
        alert("image deleted.");
        return;
      }
      alert("image delete failed.");
      // Uh-oh, an error occurred!
    });
}

export async function onDeleteContent(cId) {
  if (!onAuthTest()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onNewsDelContent", cId);
  onNewsUpdateDB(store.state.curNews);
}
