// VPRESS ADMIN Data Class
//
//

///import { faThemeisle } from "@fortawesome/free-brands-svg-icons";
//import { splice } from "core-js/core/array";
export class AQI {
  constructor() {
    this.id = "Hanoi";
    this.aqi = 100;
    this.place = "Ha Noi/ HOng Ha";
  }
}

export class Weather {
  constructor() {
    this.id = "";
    this.icon = "04dl";
    this.status = "Cloud";
    this.min = 24.01;
    this.max = 30.43;
    this.uvi = 8.8;
    this.humidity = 77;
  }
}

export class Market {
  constructor() {
    this.date = "2022-08-01";
    this.id = "";
    this.val = 1280.9;
    this.diff = 12.1;
  }
}

export class NewsImage {
  constructor() {
    this.id = "";
    this.idx = 0;
    this.name = "";
    this.deleted = false;
    this.url = null;
  }
  static create(obj) {
    var ret = new NewsImage();
    ret.id = obj.id;
    ret.idx = obj.idx;
    ret.deleted = obj.deleted;
    ret.url = obj.url;
    ret.name = obj.name;
    return ret;
  }
  static clone(orig) {
    let clone = Object.assign(Object.create(Object.getPrototypeOf(orig)), orig);
    return clone;
  }
}

// 1 news line
export class NewsLine {
  constructor(str) {
    this.id = -1;
    this.text = str;
    this.pic = { idx: -1, name: "" };
    this.cp = "";
    this.map = "";
    this.mcAnim = "";
    this.media = "";
  }
  static create(obj) {
    var cc = new NewsLine(obj.text);
    cc.id = obj.id;
    cc.pic = obj.pic;
    cc.cp = obj.cp;
    cc.map = obj.map;
    cc.mcAnim = obj.mcAnim;
    cc.media = obj.media;
    return cc;
  }

  static clone(orig) {
    let clone = Object.assign(Object.create(Object.getPrototypeOf(orig)), orig);
    return clone;
  }
}

// 記事パック (magazine) ------------- v2 ---------------------------
export class Magazine {
  constructor() {
    this.date = new Date().toISOString().split("T")[0];
    this.memo = "";
    this.id = "MMM";
    this.idx = -1; // display ordering
    this.author = "no name";
    this.lastUpdater = "no name";
    this.lastUpdated = this.date;
    this.createdAt = "";
    new Date().toISOString();
    this.updatedAt = "";
    new Date().toISOString();
    this.numChar = 0;
    this.numPic = 0;
    this.nodes = [];
    this.Aqi = [];
    this.Weather = [];
    this.Market = []; // Article id list
    this.Horoscope = [];
    //this.images = []; // class NewsImage , for title image
  }

  checkData() {
    if (this.createAt == "") {
      this.createdAt = new Date(this.date);
    }
    if (this.updatedAt == "") {
      this.updatedAt = new Date(this.date);
    }
  }

  addArticle(id) {
    this.nodes.push(id);
  }

  delArticle(idx) {
    this.nodes.splice(idx, 1);
  }

  load(obj) {
    this.date = obj.date;
    this.memo = obj.memo;
    this.id = obj.id;
    this.idx = obj.idx;
    this.author = obj.author;
    this.numPic = obj.numPic;
    this.numChar = obj.numChar;
    this.lastUpdater = obj.lastUpdater;
    this.lastUpdated = obj.lastUpdated;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
    if (!obj.createdAt) {
      this.createdAt = obj.date + "T07:00:30.123Z";
    }
    if (!obj.updatedAt) {
      this.updatedAt = obj.date + "T07:00:30.123Z";
    }

    // nodes
    this.nodes = [];
    var nodes = JSON.parse(JSON.stringify(obj.nodes));
    for (var n in nodes) {
      this.nodes.push(nodes[n]);
    }

    // AQI
    this.Aqi = [];
    if ("Aqi" in obj) {
      //console.log(obj.date);
      var aqi = JSON.parse(JSON.stringify(obj.Aqi));
      for (var m in aqi) {
        this.Aqi.push(aqi[m]);
      }
    }
    // Weathers
    this.Weather = [];
    if ("Weather" in obj) {
      var weather = JSON.parse(JSON.stringify(obj.Weather));
      for (m in weather) {
        this.Weather.push(weather[m]);
      }
    }
    //Market
    this.Market = [];
    if ("Market" in obj) {
      var market = JSON.parse(JSON.stringify(obj.Market));
      for (m in market) {
        this.Market.push(market[m]);
      }
    }

    // images is not used in V2.0
    this.images = [];
    /*
    var images = JSON.parse(JSON.stringify(obj.images));
    for (var m in images) this.images.push(NewsImage.create(images[m]));
    //this.images = JSON.parse(JSON.stringify(obj.images));
    */
  }
  clone(orig) {
    let clone = Object.assign(Object.create(Object.getPrototypeOf(orig)), orig);
    return clone;
  }
}

// 記事単体 (article) -------------------- v2 --------------------------
export class Article {
  constructor() {
    //console.log("newsData constructor");
    this.date = new Date().toISOString().split("T")[0];
    this.deletedAt = "";
    this.type = "";
    this.text = "";
    this.memo = "";
    this.id = "AAA";
    this.magID = "MMM"; // magazine data id, parent id
    this.idx = -1; // display ordering
    this.author = "no name";
    this.lastUpdater = "no name";
    this.createdAt = new Date().toISOString();
    this.updatedAt = new Date().toISOString();
    this.urls = "";
    this.lines = []; // class NewsLine
    this.images = []; // class NewsImage
  }

  checkData() {
    if (this.createAt == "") {
      this.createdAt = new Date(this.date);
    }
    if (this.updatedAt == "") {
      this.createdAt = new Date(this.date);
    }
  }

  /*  
  static load(obj) {
    this.date = obj.date;
    this.memo = obj.memo;
    this.id = obj.id;
    this.magID = obj.magID; // magazine data id, parent id
    this.idx = obj.idx; // display ordering
    this.author = obj.author;
    this.lastUpdater = obj.lastUpdate;
    this.urls = obj.urls;
    this.lines=[];
    this.images=[];
    for( var oo in obj.lines ) {
       this.lines.push(  NewsLine.create( obj.lines[oo]));
    }
    for( var ii in obj.images) {
       this.images.push(  NewsImage.create( obj.images[ii]));
    }
  }
  */
  static clone(orig) {
    var ret = new Article();
    let clone = Object.assign(Object.create(Object.getPrototypeOf(orig)), orig);
    for (var n in orig.lines) {
      var line = NewsLine.clone(orig.lines[n]);
      ret.lines.push(line);
    }
    for (var vv in orig.images) {
      var img = NewsImage.clone(orig.images[vv]);
      ret.images.push(img);
    }
    return clone;
  }

  static create(obj) {
    var ret = new Article();
    if (obj.type) ret.type = obj.type;
    ret.date = obj.date;
    ret.deletedAt = obj.deletedAt;
    ret.updatedAt = obj.updatedAt;
    ret.memo = obj.memo;
    ret.id = obj.id;
    ret.magID = obj.magID;
    ret.idx = obj.idx;
    ret.author = obj.author;
    ret.lastUpdater = obj.lastUpdater;
    ret.urls = obj.urls;
    ret.text = obj.text;
    ret.lines = [];
    ret.images = [];
    for (var n in obj.lines) {
      var line = obj.lines[n];
      if (null == line) {
        line = new NewsLine("error data");
      }
      var ln = new NewsLine(line.text);
      ln.id = line.id;
      ln.pic = line.pic;
      ln.mcAnim = line.mcAnim;
      ln.map = line.map;
      ret.lines.push(ln);
    }
    for (var ii in obj.images) {
      ret.images.push(NewsImage.create(obj.images[ii]));
    }

    return ret;
  }
  /*
  getLine(idx) {
    if (idx < 0) return null;
    if (this.lines[idx] == undefined) {
      this.lines[idx] = new NewsLine();
      this.lines[idx].idx = idx;
    }
    return this.lines[idx];
  }
  */
  // image

  cancelImage(idx) {
    for (var line in this.lines) {
      if (line.pic == idx) {
        line.pic = -1;
      }
    }
  }
  addLine(str) {
    var line = new NewsLine(str);
    line.id = this.lines.length;
    this.lines.push(line);
  }
  updateMemo(txt) {
    this.memo = txt;
  }
  updateLinePose(idx, pose) {
    if (idx >= this.lines.length) {
      return;
    }
    this.lines[idx].mcAnim = pose;
    //console.log(this);
  }
  updateLine(idx, txt) {
    if (idx >= this.lines.length) {
      return;
    }
    this.lines[idx].text = txt;
  }
  updateAllLines(lines) {
    for (var n in lines) {
      //console.log(n + " ? " + this.lines.length);
      if (this.lines.length <= n) {
        this.addLine(lines[n]);
      } else {
        this.lines[n].text = lines[n];
      }
    }
    var max = this.lines.length - lines.length;
    //console.log("max=" + max);
    for (var ii = 0; ii < max; ii++) {
      this.lines.pop();
    }
  }

  picExist(pid) {
    for (var ii in this.images) {
      if (this.images[ii].id == pid) return pid;
    }
    return -1;
  }
  loadLines(lines, images) {
    for (var ii in lines) {
      var org = lines[ii];
      var ln = new NewsLine(lines[ii].text);
      ln.id = org.id;
      ln.mcAnim = org.mcAnim;
      ln.map = org.map;
      ln.media = org.media;
      if (org.pic >= 0) {
        if (this.picExist(org.pic) >= 0) {
          ln.pic = {
            idx: org.pic,
            name: images[org.pic].name,
          };
        } else {
          ln.pic = {
            idx: this.images.length,
            name: images[org.pic].name,
          };
          //ln.pic = this.images.length;
          // copy image
          var img = new NewsImage();
          img.deleted = images[lines[ii].pic].deleted;
          img.id = lines[ii].pic;
          img.idx = ln.pic;
          img.name = images[lines[ii].pic].name;
          img.url = images[lines[ii].pic].url;
          this.images.push(img);
        }
      }
      this.lines.push(ln);
    }
  }
  updateWriter(name) {
    this.lastUpdater = name;
  }
  getImageLen() {
    var ll = 0;
    for (var idx in this.images) {
      if (!this.images[idx].deleted) ll++;
    }
    return ll;
  }
  getText() {
    var text = "";
    for (var n in this.nodes) {
      text = text + this.nodes[n].text;
    }
    return text;
  }

  getNextNodeId() {
    if (this.nodes.length == 0) return 0;
    return this.nodes[this.nodes.length - 1].id + 1;
  }
  // image
  addImage(url, name) {
    var img = new NewsImage();
    img.id = "";
    img.idx = this.images.length;
    img.url = url;
    img.name = name;
    this.images.push(img);
    return img;
  }
  delImage(idx) {
    console.log(
      "---------------------------------------\n Article Delete Image " +
        idx +
        "\n"
    );
    this.images[idx].deleted = true;
    for (var n in this.nodes) {
      this.nodes[n].cancelImage(idx);
    }
  }

  getImageName(idx) {
    return this.images[idx].name;
  }
  //node
  addNode() {
    var node = new Article();
    node.id = this.getNextNodeId(); //this.nodes.length;
    //this.nodes.push(node);
    return node;
  }
}

module.export = {
  Magazine,
  Article,
  NewsLine,
  NewsImage,
};
