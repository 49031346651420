import { createRouter, createWebHistory } from "vue-router";
//import Home from "@/views/Home.vue";
import News from "@/views/News.vue";
import Magazines from "@/views/Magazines.vue";
import MagazinesInfo from "@/views/MagazinesInfo.vue";
import MagazineEdit from "@/views/MagazineEdit.vue";
import ArticleEdit from "@/views/ArticleEdit.vue";
// import NewsList from "@/views/NewsList.vue";
import NewsEdit from "@/views/NewsEdit.vue";
import ContentEdit from "@/views/ContentEdit.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/Magazines",
  },
  {
    path: "/Home",
    name: "Home",
    redirect: "/Magazines",
  },
  // v2
  {
    path: "/magazines",
    name: "Magazines",
    component: Magazines,
  },
  {
    path: "/magazinesInfo",
    name: "MagazinesInfo",
    component: MagazinesInfo,
  },

  {
    path: "/magazine-edit",
    name: "MagazineEdit",
    component: MagazineEdit,
  },
  {
    path: "/article-edit",
    name: "ArticleEdit",
    component: ArticleEdit,
  },
  // v1
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/news-edit",
    name: "NewsEdit",
    component: NewsEdit,
  },
  {
    path: "/content-edit",
    name: "ContentEdit",
    component: ContentEdit,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
];

const appRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

import store from "../store";

appRouter.beforeEach((to, from, next) => {
  var token = store.state.token;

  if (token == "") {
    console.log("no login session");
    if (to.path != "/sign-in") {
      appRouter.push("/sign-in");
    }
  }
  next();
});

export default appRouter;
