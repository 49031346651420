<template>
  <div class="card">
    <div class="card-body px-0 pb-2">
      <p>{{ page }}/{{ max }}</p>
      <div :key="counter" class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th>ID</th>

              <th>Issue/号</th>
              <th>Articles</th>
              <th>Last Update</th>

              <th>Author</th>
              <th>Pictures</th>
              <th>Letters</th>
              <th>TXT</th>
              <th>DL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(oo, index) in list" :key="oo.id">
              <td @click="edit(index)" :title="oo.id">
                {{ getIdx(index) }}
              </td>
              <td @click="edit(index)" :title="oo.id">
                {{ oo.date }}
              </td>
              <td>
                {{ oo.nodes.length }}
              </td>
              <td>
                {{ oo.lastUpdater }}
              </td>
              <td>
                {{ oo.author }}
              </td>

              <td>
                {{ oo.numPic }}
              </td>
              <td>
                {{ oo.numChar }}
              </td>
              <td><img @click="downloadText(oo.id)" src="/dl32x32.ico" /></td>
              <td><img @click="download(oo.id)" src="/dl32x32.ico" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
table th {
  text-align: center;
  background-color: #647070 !important;
  color: #eee !important;
}

table td {
  text-align: left;
  border: 1px #eee solid !important ;
  color: #ccc !important;
}
</style>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import { onDownload } from "../../RepositoryV2";

export default {
  name: "news-card",
  props: ["counter", "max", "limit", "page"],
  data() {
    return {
      newsList: [],
    };
  },
  methods: {
    getIdx(idx) {
      return parseInt(this.page) * parseInt(this.limit) + idx;
    },
    downloadText(id) {
      onDownload(id, false);
    },
    download(id) {
      onDownload(id, true);
    },
    edit(id) {
      this.$store.commit("onMagazineSelect", id);
      this.$router.push("/magazine-edit");
    },
    /*
    getImgLen(oo) {
      var cnt = 0;
      for (var nn in oo.nodes) {
        cnt += oo.nodes[nn].images.length;
      }
      return cnt;
    },
    getLen(oo) {
      var cnt = 0;
      for (var nn in oo.nodes) {
        cnt += oo.nodes[nn].text.length;
      }
      return cnt;
    },
  */
  },
  computed: {
    list() {
      return this.$store.state.magazines;
    },
  },
  components: {},
  mounted() {
    setTooltip();
  },
};
</script>
