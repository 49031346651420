<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>News</h6>
          <p class="text-sm mb-0">
            <i class="fa fa-check text-info" aria-hidden="true"></i>
            <span class="font-weight-bold ms-1">latests contents</span>
          </p>
        </div>

        <div class="col-lg-6 col-5 my-auto text-end">
          <div class="dropdown float-lg-end pe-4">
            <a
              class="cursor-pointer"
              id="dropdownTable"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
            </a>
            <ul
              class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
              aria-labelledby="dropdownTable"
            >
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Action</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Another action</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;"
                  >Something else here</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body px-0 pb-2">
      <div :key="counter" class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                num of News
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Last Update
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Author
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Pictures
              </th>
              <th>Letters</th>
              <th>TXT</th>
              <th>DL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(oo, index) in list" :key="oo.id">
              <td @click="edit(index)" :title="oo.id">
                {{ index }}
              </td>
              <td @click="edit(index)" :title="oo.id">
                {{ oo.date }}
              </td>
              <td>
                {{ oo.nodes.length }}
              </td>
              <td>
                {{ oo.lastUpdater }}
              </td>
              <td>
                {{ oo.auther }}
              </td>

              <td>
                {{ oo.getImageLen() }}
              </td>
              <td>
                {{ getLen(oo) }}
              </td>
              <td><img @click="downloadText(oo.id)" src="/dl32x32.ico" /></td>
              <td><img @click="download(oo.id)" src="/dl32x32.ico" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
table th {
  text-align: center;
  background-color: #aacfff;
}

table td {
  text-align: center;
  border: 1px #eee solid !important ;
}
</style>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import { onDownload } from "../../Repository";

export default {
  name: "news-card",
  props: ["counter"],
  data() {
    return {
      newsList: [],
    };
  },
  methods: {
    downloadText(id) {
      onDownload(id, false);
    },
    download(id) {
      onDownload(id, true);
    },
    edit(id) {
      this.$store.commit("onNewsSelect", id);
      this.$router.push("/news-edit");
    },
    getLen(oo) {
      var cnt = 0;
      for (var nn in oo.nodes) {
        cnt += oo.nodes[nn].text.length;
      }
      return cnt;
    },
  },
  computed: {
    list() {
      return this.$store.state.newsAry;
    },
  },
  components: {},
  mounted() {
    setTooltip();
  },
};
</script>
