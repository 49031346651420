<style>
.side-sw {
  z-index: 100000;
  border: solid 1px rgb(209, 209, 209);
  position: fixed;
  top: 40px;
  left: 0;
  padding: 0px 3px;
  font-size: 20px;
  color: #fff;
  background-color: rgb(87, 87, 87);
}
.btnBack {
  position: fixed;
  top: 20px !important;
  right: 140px !important;
  width: 150px !important;
}
.date {
  background-color: #014e58;
}
.card {
  background-color: #333 !important;
  color: #eee !important;
}
td img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

textarea,
input,
select {
  background-color: rgb(48, 50, 57) !important;
  color: #fff !important;
  border: solid 1px #bbb;
  border-radius: 8px;
  padding: 4px 12px !important;
  font-size: 1.2em;
}
.input-group-text {
  background-color: rgb(73, 76, 86) !important;
}
span,
label,
p,
div {
  color: #eee !important;
  padding: 4px;
  background-color: none;
}
.dropimage {
  min-width: 140px;
  min-height: 80px;
}
h1,
h2,
h3,
h4 {
  color: #fff !important;
}
div#app button {
  border: 1px solid #ccc;
  border-radius: 8px !important;
  padding: 0px 8px;
  margin: 0px;
}

div#app button.btn {
  border: 1px solid #ccc;
  border-radius: 8px !important;
  margin: 0px 8px;
  padding: 8px 8px;
}

html,
body,
div#app {
  background-color: rgb(59, 59, 59) !important;
  color: rgb(239, 228, 228);
}

.navbar-vertical {
  z-index: 1000;
}
</style>

<template>
  <div class="side-sw" @click="toggleSideNav()">↔</div>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- nav -->
    <!--
    <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    -->
    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
    <configurator
      :toggle="toggleConfigurator"
      :class="[
        this.$store.state.showConfig ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
//import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    //Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    toggleSideNav() {
      console.log("tgl");
      this.$store.state.showSidenav = !this.$store.state.showSidenav;
    },
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
