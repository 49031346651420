// VPRESS ADMIN Data Class
//
//

export class NewsImage {
  constructor() {
    this.id = "";
    this.idx = 0;
    this.name = "";
    this.deleted = false;
    this.url = null;
  }
  static create(obj) {
    var ret = new NewsImage();
    ret.id = obj.id;
    ret.idx = obj.idx;
    ret.deleted = obj.deleted;
    ret.url = obj.url;
    ret.name = obj.name;
    return ret;
  }
}

// 1 news line
export class NewsLine {
  constructor(str) {
    this.id = -1;
    this.text = str;
    this.pic = -1;
    this.cp = "";
    this.map = "";
    this.mcAnim = "";
    this.media = "";
  }
}

// 1つの記事
export class NewsNode {
  constructor() {
    this.id = -1;
    this.text = ""; //この記事の全文
    this.memo = ""; // url reference ( comma list )
    this.lines = []; // class NewsLine
  }
  static create(obj) {
    var ret = new NewsNode();
    ret.text = obj.text;
    ret.memo = obj.memo;
    ret.id = obj.id;
    for (var n in obj.lines) {
      var line = obj.lines[n];
      //console.log(line);
      if (null == line) {
        line = new NewsLine("error data");
      }
      var ln = new NewsLine(line.text);
      ln.id = line.id;
      ln.pic = line.pic;
      ln.mcAnim = line.mcAnim;
      ln.map = line.map;
      ret.lines.push(ln);
    }
    return ret;
  }
  getLine(idx) {
    if (idx < 0) return null;
    if (this.lines[idx] == undefined) {
      this.lines[idx] = new NewsLine();
      this.lines[idx].id = idx;
    }
    return this.lines[idx];
  }
  cancelImage(idx) {
    for (var line in this.lines) {
      if (line.pic == idx) {
        line.pic = -1;
      }
    }
  }
  addLine(str) {
    var line = new NewsLine(str);
    line.id = this.lines.length;
    this.lines.push(line);
  }
  updateMemo(txt) {
    this.memo = txt;
  }
  updateLinePose(idx, pose) {
    if (idx >= this.lines.length) {
      return;
    }
    this.lines[idx].mcAnim = pose;
    console.log(this);
  }
  updateLine(idx, txt) {
    if (idx >= this.lines.length) {
      return;
    }
    this.lines[idx].text = txt;
  }
  updateAllLines(lines) {
    //console.log("len=" + lines.length);
    //console.log("this.len=" + this.lines.length);
    for (var n in lines) {
      //console.log(n + " ? " + this.lines.length);
      if (this.lines.length <= n) {
        this.addLine(lines[n]);
      } else {
        this.lines[n].text = lines[n];
      }
    }
    var max = this.lines.length - lines.length;
    //console.log("max=" + max);
    for (var ii = 0; ii < max; ii++) {
      this.lines.pop();
    }
  }
}

// 1日の記事
export class NewsObj {
  constructor() {
    //console.log("newsData constructor");
    this.date = "2022-04-01";
    this.memo = "";
    this.id = "VVV";
    this.idx = -1;
    this.auther = "no name";
    this.lastUpdater = "no name";
    this.nodes = []; // class NewsNode
    this.images = []; // class NewsImage
  }
  load(obj) {
    this.date = obj.date;
    this.memo = obj.memo;
    this.id = obj.id;
    this.idx = obj.idx;
    this.auther = obj.auther;
    this.lastUpdater = obj.lastUpdater;
    this.nodes = [];
    this.images = [];
    var nodes = JSON.parse(JSON.stringify(obj.nodes));
    for (var n in nodes) {
      // console.log(n);
      // console.log(nodes[n]);
      this.nodes.push(NewsNode.create(nodes[n]));
    }
    var images = JSON.parse(JSON.stringify(obj.images));
    for (var m in images) this.images.push(NewsImage.create(images[m]));
    //this.images = JSON.parse(JSON.stringify(obj.images));
  }
  updateWriter(name) {
    this.lastUpdater = name;
  }
  getImageLen() {
    var ll = 0;
    for (var idx in this.images) {
      if (!this.images[idx].deleted) ll++;
    }
    return ll;
  }
  getText() {
    var text = "";
    for (var n in this.nodes) {
      text = text + this.nodes[n].text;
    }
    return text;
  }

  getNextNodeId() {
    if (this.nodes.length == 0) return 0;
    return this.nodes[this.nodes.length - 1].id + 1;
  }
  // image
  addImage(url, name) {
    var img = new NewsImage();
    img.id = "";
    img.idx = this.images.length;
    img.url = url;
    img.name = name;
    //console.log(img);
    this.images.push(img);
    return img;
  }

  delContent(idx) {
    this.nodes.splice(idx, 1);
  }

  delImage(idx) {
    console.log(
      "---------------------------------------\n Delete Image " + idx + "\n"
    );
    this.images[idx].deleted = true;
    for (var n in this.nodes) {
      this.nodes[n].cancelImage(idx);
    }
  }
  getImageName(idx) {
    return this.images[idx].name;
  }

  //node
  addNode() {
    var node = new NewsNode();
    node.id = this.getNextNodeId(); //this.nodes.length;
    //this.nodes.push(node);
    return node;
  }

  /*
  updateNode(node) {
    for (var n in this.nodes) {
      if (node.id == this.nodes[n].id) {
        this.nodes[n] = Object.assign({}, node);
      }
    }
  } */

  //
  //  loadJson(fname) {
  //    // this.json = $.getJSON(fname);
  //  }
}

module.export = {
  NewsObj,
  NewsLine,
  NewsNode,
  NewsImage,
};
