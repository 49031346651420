<template>
  <!--
  <button @click="convert">convert</button>
 -->
  <div class="py-0 container-fluid">
    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <news-card v-bind:counter="render" />
        </div>
      </div>
    </div>
    <a
      class="btn bg-gradient-success mt-1"
      href="#"
      type="button"
      @click="createNews"
      >Create News</a
    >
  </div>
</template>
<script>
import { Magazine, Article } from "../newsDataV2.js";
import NewsCard from "./components/NewsCard.vue";
import { onNewsCreate, onNewsGet } from "../Repository";
import {
  onMagazineCreate,
  onMagazineUpdate,
  onArticleCreate,
  //  onArticleList,
  onArticleUpdate,
} from "../RepositoryV2";

export default {
  name: "dashboard-default",
  components: { NewsCard },
  data() {
    return {
      render: 0,
    };
  },
  mounted() {
    onNewsGet();
    this.render++;
    console.log(this.$store.state.newsAry);
    //this.$store.dispatch("loadNews");
  },
  methods: {
    // for data convert from v1 to v2
    /*
    extImageDict: function (news) {
      news.dic = {};
      for (var ii in news.images) {
        news.dic[ii] = news.images[ii].url;
      }
    },
     */
    convertOne: function (news) {
      /*
      this.extImageDict(news);
      console.log(news.dic);
      */
      console.log(news);
      var mobj = new Magazine();
      mobj.author = news.auther; // news.auther is mistyped implementation
      mobj.date = news.date;
      mobj.idx = news.idx;
      mobj.memo = news.memo;
      mobj.lastUpdater = news.lastUpdater;
      onMagazineCreate(mobj).then((ret) => {
        mobj.id = ret.obj.id;
        if (!ret.res) {
          console.log(" can not create magazine");
          console.log(ret);
        }
        console.log(ret);
        for (var ar in news.nodes) {
          var node = news.nodes[ar];
          var art = new Article();
          console.log(mobj.id);
          art.magID = mobj.id;
          art.id = "firestore id";
          art.idx = node.id;
          art.author = mobj.author;
          art.lastUpdater = mobj.lastUpdater;
          art.date = mobj.date;
          art.urls = node.memo;
          art.text = node.text;
          art.loadLines(node.lines, news.images);
          onArticleCreate(art).then((ret2) => {
            //console.log(ret2.id);
            ret2.obj.id = ret2.id;
            onArticleUpdate(ret2.obj);
            mobj.nodes.push(ret2.id);
            onMagazineUpdate(mobj); // add the article to the magazine
          });
          //  break;
        }
      });
    },
    convert: function () {
      for (var aa in this.$store.state.newsAry) {
        //console.log(aa);
        //break;
        this.convertOne(this.$store.state.newsAry[aa]);
      }
    },
    createNews: function () {
      onNewsCreate();
      // this.render++;
      /*
      var news = this.$store.dispatch("createNews");
      console.log(news);
      if (!news) {
        alert("create error");
      }
    },
      this.$router.push("/news-edit");
    */
    },
  },
};
</script>
