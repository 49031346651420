//import firebase from "firebase/compat/app";
import { Magazine, Article } from "./newsDataV2.js";
import { onBeforeUnmount } from "vue";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";

import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadString,
  //uploadBytes,
  //  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import {
  doc,
  updateDoc,
  getFirestore,
  collection,
  addDoc,
  getDocs,
  limit,
  limitToLast,
  endBefore,
  startAfter,
  // offset,
  orderBy,
  where,
  query,
  //getDoc,
  //  runTransaction,
} from "firebase/firestore";

import "firebase/compat/auth";
import store from "./store";
import appRouter from "./router";

console.log(
  " firebase initialization start----------------------------------------"
);
// init
const firebaseConfig = {
  apiKey: "AIzaSyB-0eEuhuFA4ePgTAxqxzO54KaG0omUSS0",
  authDomain: "vpress-admin-dev.firebaseapp.com",
  projectId: "vpress-admin-dev",
  storageBucket: "vpress-admin-dev.appspot.com",
  messagingSenderId: "370073973892",
  appId: "1:370073973892:web:a9cc5f7e202cd48e487ed1",
};

// setup firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const coMagazines = "magazines";
const coArticles = "articles";

//const coMagazines = "test-magazines";
//const coArticles = "test-articles";

const storage = getStorage(firebaseApp);

// for data version V2
const magDB = collection(db, coMagazines); // { id: {{myid}}, date:"yyyy-mm-dd", articles:[ (id list) ] }
const articleDB = collection(db, coArticles); // { nid:{{newsId}}, id:{{myid}}, date:"yyyy-mm-dd",urls:"text", story:{ (data body)}   }

console.log("!!! add auth listener --------------------");
export const authListener2 = onAuthStateChanged(auth, function (user) {
  console.log("user changed: " + user.email);
});

onBeforeUnmount(() => {
  // clear up listener
  authListener2();
});

// AUTH ---------------------------------------------------------------------------------------------------
export function authInit2() {
  setPersistence(auth, browserSessionPersistence);
}

// -------------------------------------------
export function onAuthByEmail2(mail, passwd) {
  if (onAuthTestV2()) {
    onAuthLogout2();
  }
  console.log("onAuthChanged");
  signInWithEmailAndPassword(auth, mail, passwd)
    .then(
      // 成功時の処理
      (data) => {
        console.log("login succeeded.");
        console.log(data);
        console.log(data.user.email);
        store.commit("onAuthChanged", data.user.email, data.user.accessToken);
        // se
        appRouter.replace("/magazines");
      }
    )
    .catch(
      // エラー時の処理
      (error) => {
        console.log(error);
        store.commit("onLogout");
      }
    );
}

//--------------------------
export function onAuthLogout2() {
  auth.signOut().then(() => {
    store.commit("onLogout");
    appRouter.replace("login");
  });
}

//--------------------------
export function onAuthTestV2() {
  var user = auth.currentUser;
  //console.log(user);
  if (!user) return false;
  return user.accessToken != "";
}

// Articles  (V2) ----------------------------------------------------------------------------------

export async function onNewArticle() {
  if (!onAuthTestV2()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // store は try catch の外！
  var id;
  try {
    var obj = new Article();
    obj.magID = store.state.curNews.id;
    obj.text = "empty news text";
    obj.idx = store.state.articles.length;
    obj.author = store.state.mail;
    obj.lastUpdater = store.state.mail;
    obj.createdAt = new Date().toISOString();
    obj.lastUpdated = new Date().toISOString();
    const articleRef = await addDoc(articleDB, JSON.parse(JSON.stringify(obj)));
    console.log("article written with ID: ", articleRef.id);
    id = articleRef.id;
    obj.id = id;
    onArticleUpdate(obj);
    store.commit("onArticleLoad", obj);
    store.commit("onMagazineAddArticle", id);
    onMagazineUpdate(store.state.curNews);
  } catch (e) {
    alert("add data error");
    console.error("Error adding document: ", e);
    return { res: false, msg: "store ", id: null };
  }
  return { res: true, msg: "add ok", id: id };
}

export async function onArticleCreate(obj) {
  if (!onAuthTestV2()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // store は try catch の外！
  var id = "no id";
  try {
    const articleRef = await addDoc(articleDB, JSON.parse(JSON.stringify(obj)));
    console.log("Document written with ID: ", articleRef.id);
    id = articleRef.id;
  } catch (e) {
    alert("add data error");
    console.error("Error adding document: ", e);
    return { res: false, msg: "store ", id: null, obj: obj };
  }
  store.commit("onArticleLoad", obj);
  return { res: true, msg: "add ok", id: id, obj: obj };
}

// News Date update----------------
export async function onArticleDateUpdate(date) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onArticleDateUpdate", date);
  onArticleUpdate(store.state.curAr);
}

// -------------------------------------------------
export async function onArticleUpdate(data) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  console.log("article update ");
  console.log(data);
  data.lastUpdater = store.state.mail;
  data.updatedAt = new Date().toISOString();
  await updateDoc(doc(articleDB, data.id), JSON.parse(JSON.stringify(data)));
}
export async function getNewArticleID() {
  return articleDB.doc().id;
}
// ---------------- list --------------------
//const q = query(citiesRef, where('country', 'in', ['USA', 'Japan']));
export async function onArticleList(idList) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  const split = (array, n) =>
    array.reduce(
      (a, c, i) =>
        i % n == 0 ? [...a, [c]] : [...a.slice(0, -1), [...a[a.length - 1], c]],
      []
    );
  var id10List = split(idList, 10);
  console.log("onArticleList");
  store.commit("onArticleReset");
  for (var ll in id10List) {
    const qq = query(articleDB, where("id", "in", id10List[ll]), limit(10));
    const querySnapshot = await getDocs(qq);
    querySnapshot.forEach((_doc) => {
      var zobj = _doc.data();
      zobj.id = _doc.id;
      var ar = Article.create(zobj);
      store.commit("onArticleLoad", ar);
    });
  }
  store.state.articles.sort(sortDate);
}
Date.prototype.setISO8601 = function (string) {
  var regexp =
    "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
    "(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(.([0-9]+))?)?" +
    "(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
  var d = string.match(new RegExp(regexp));

  var offset = 0;
  var date = new Date(d[1], 0, 1);

  if (d[3]) {
    date.setMonth(d[3] - 1);
  }
  if (d[5]) {
    date.setDate(d[5]);
  }
  if (d[7]) {
    date.setHours(d[7]);
  }
  if (d[8]) {
    date.setMinutes(d[8]);
  }
  if (d[10]) {
    date.setSeconds(d[10]);
  }
  if (d[12]) {
    date.setMilliseconds(Number("0." + d[12]) * 1000);
  }
  if (d[14]) {
    offset = Number(d[16]) * 60 + Number(d[17]);
    offset *= d[15] == "-" ? 1 : -1;
  }

  offset -= date.getTimezoneOffset();
  var time = Number(date) + offset * 60 * 1000;
  this.setTime(Number(time));
};

function sortDate(a, b) {
  //  var date1 = new Date().setISO8601(a.date);
  //  var date2 = new Date().setISO8601(b.date);
  //console.log(date1);
  return a.date > b.date ? 1 : -1;
}
// Magazines (V2) ----------------------------------------------------------------------------------

export function onMagazineSum(data) {
  var cntImg = 0;
  var cntTxt = 0;
  for (var aa in store.state.articles) {
    cntImg += store.state.articles[aa].images.length;
    cntTxt += store.state.articles[aa].text.length;
  }
  //  store.state.curNews.numPic = cntImg;
  //  store.state.curNews.numChar = cntTxt;
  data.numPic = cntImg;
  data.numChar = cntTxt;

  console.log("sum");
  console.log(cntImg);
}

// ---------------------------------------------------------------------------------
// create ----------------
export async function onMagazineCreate() {
  if (!onAuthTestV2()) {
    alert("please login");
    appRouter.push("/sign-in");
    return false, "auth";
  }
  // store は try catch の外！
  try {
    var magObj = new Magazine();
    magObj.author = store.state.mail;
    magObj.lastUpdater = store.state.mail;
    //magObj.lastUpdated = new Date().toISOString();
    magObj.updatedAt = new Date().toISOString();
    const magRef = await addDoc(magDB, JSON.parse(JSON.stringify(magObj)));
    console.log("Magazine has been written with ID: ", magRef.id);
    magObj.id = magRef.id;
    onMagazineUpdate(magObj);
    onMagazineList();
  } catch (e) {
    alert("add data error");
    console.error("Error adding document: ", e);
    return { res: false, msg: "add error", obj: null };
  }
  store.commit("onMagazineAdd", magObj);
  console.log(magObj);
  return { res: true, msg: "add ok", obj: magObj };
}
// Magazine List ---------------------------
export async function onMagazineNextList() {
  store.state.mOfs += store.state.mPageLimit;
  store.state.mPage += 1;
  var snap = store.state.magazineSnapshot;
  const qq = query(
    magDB,
    orderBy("date", "desc"),
    limit(store.state.mPageLimit),
    startAfter(snap.docs[snap.docs.length - 1])
  );
  const querySnapshot = await getDocs(qq);
  loadMag(querySnapshot);
}

export async function onMagazinePrevList() {
  store.state.mOfs -= store.state.mPageLimit;
  store.state.mPage -= 1;
  var snap = store.state.magazineSnapshot;
  const qq = query(
    magDB,
    orderBy("date", "desc"),
    limitToLast(store.state.mPageLimit),
    endBefore(snap.docs[0])
  );
  const querySnapshot = await getDocs(qq);
  loadMag(querySnapshot);
}

async function loadMag(querySnapshot) {
  store.commit("onMagazineReset");
  store.commit("onMagazineSnapshot", querySnapshot);
  querySnapshot.forEach((_doc) => {
    var obj = _doc.data();
    obj.id = _doc.id;
    var mags = new Magazine();
    mags.load(obj);
    store.commit("onMagazineLoad", mags);
  });
}

export async function onMagazineList() {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  // get list
  var num = 0;
  var snap = await getDocs(magDB);
  num = snap.docs.length;
  store.commit(
    "onMagazinePageMax",
    Math.floor((num + store.state.mPageLimit - 1) / store.state.mPageLimit)
  );
  store.commit("onMagazinesNum", num);
  const qq = query(
    magDB,
    orderBy("date", "desc"),
    limit(store.state.mPageLimit + 0)
  );
  const querySnapshot = await getDocs(qq);
  loadMag(querySnapshot);
}

export async function onMagazineUpdate(data) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  console.log("update magazine");
  onMagazineSum(data);
  console.log(data);
  await updateDoc(doc(magDB, data.id), JSON.parse(JSON.stringify(data)));
}

// News Memo update----------------
export async function onMagazineMemoUpdate(memo) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onMagazineMemoUpdate", memo);
  onMagazineUpdate(store.state.curNews);
}

// News Date update----------------
export async function onMagazineDateUpdate(date) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  store.commit("onMagazineDateUpdate", date);
  onMagazineUpdate(store.state.curNews);
}

// FILE ----------------------------------------------------------------------------------
export async function onFileCreate(file, newsId) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  console.log("onFileCreate");
  console.log("upload to = images/" + newsId);
  const imageRef = ref(storage, "images/" + newsId + "/" + file.fileName);
  await uploadString(imageRef, file.fileData.split(",")[1], "base64")
    .then((snapshot) => {
      console.log("File metadata:", snapshot.metadata);
      getDownloadURL(snapshot.ref).then((url) => {
        console.log("File available at", url);
        //store.dispatch("registerImage", url, snapshot.metadata.name);
        var name = snapshot.metadata.name;
        store.commit("onArticleAddImage", { url, name });
        onArticleUpdate(store.state.curAr);
      });
    })
    .catch((error) => {
      console.error("Upload failed", error);
      return "";
    });
}

export async function onDeleteImage(imageId) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  console.log(imageId);
  const name = store.state.curNews.getImageName(imageId);

  const imageRef = ref(
    storage,
    "images/" + store.state.curNews.id + "/" + name
  );
  // Delete the file
  deleteObject(imageRef)
    .then(() => {
      store.commit("onMagazineDelImage", imageId);
      // File deleted successfully
      onMagazineUpdate(store.state.curNews);
      alert("image deleted.");
    })
    .catch((error) => {
      if (error.code.indexOf("object-not-found") >= 0) {
        store.commit("onMagazineDelImage", imageId);
        alert("image deleted.");
        return;
      }
      alert("image delete failed.");
      // Uh-oh, an error occurred!
    });
}

export async function onDeleteArticleImage(imageId) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }
  console.log(imageId);
  const name = store.state.curAr.getImageName(imageId);

  const imageRef = ref(storage, "images/" + store.state.curAr.id + "/" + name);
  // Delete the file
  deleteObject(imageRef)
    .then(() => {
      store.commit("onArticleDelImage", imageId);
      // File deleted successfully
      onArticleUpdate(store.state.curAr);
      alert("image deleted.");
    })
    .catch((error) => {
      if (error.code.indexOf("object-not-found") >= 0) {
        store.commit("onMagazineDelImage", imageId);
        alert("image deleted.");
        return;
      }
      alert("image delete failed.");
      // Uh-oh, an error occurred!
    });
}

// ----------------------------------------------
export async function onDeleteArticle(aId) {
  if (!onAuthTestV2()) {
    alert("please login");
    return false, "auth";
  }

  // delete from await deleteDoc(doc(db, "cities", "DC"));
  //await deleteDoc(doc(db, "cities", "DC"));
  store.commit("onArticleDeletedAt", aId); // article[aId].deletedAt = now;
  onArticleUpdate(store.state.articles[aId]).then(() => {
    store.commit("onArticleDel", aId); // article[aId]).deletedAt = now;
  });

  // delete from cache
  store.commit("onMagazineDelArticle", aId);
  onMagazineUpdate(store.state.curNews).then(() => {});
}

// create ZIP file -------------------------------------------
export async function onDownload(id, imageOut) {
  var obj = null;
  for (var idx in store.state.magazines) {
    if (store.state.magazines[idx].id == id) {
      obj = store.state.magazines[idx];
      break;
    }
  }
  if (obj == null) {
    alert("no news obj = " + id);
    return;
  }
  //store.commit("onMagazineSelect", obj.id);
  onArticleList(obj.nodes).then(() => {
    ExportZip(obj, store.state.articles, imageOut);
  });
}

export async function ExportZip(mag, articles, imageOut) {
  // ------------------
  var newsText = "";
  var memoText = "";
  var picsText = "";
  var files = [];

  // -------------------------------------------------
  function filename2str(fn) {
    var body = fn.split(".")[0];
    var dd = body.split("_");
    var res = body + "," + dd[1];
    return res;
  }

  function urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  // create image list
  //var imgList=[];
  var zip = new JSZip();
  for (var m in articles) {
    var node = articles[m];
    for (var nn in node.images) {
      files.push(node.images[nn].name);
      if (imageOut) {
        // zip image files
        if (!node.images[nn].deleted) {
          zip.file(
            "_pic/" + node.images[nn].name,
            urlToPromise(node.images[nn].url),
            {
              binary: true,
            }
          );
        }
      }
    }
  }

  var lc = 0;
  for (m in articles) {
    node = articles[m];
    // text
    newsText = newsText + node.text + "\n";
    var str = memoText + node.lines[0].text + "\n";
    var strs = str.split("##");
    memoText = memoText + (parseInt(m) + 1) + ")" + strs[1];
    memoText = memoText + node.urls + "\n";
    for (var ll in node.lines) {
      var name = node.lines[ll].pic.name;
      if (name != "") {
        picsText = picsText + lc + "," + filename2str(name) + "\n";
      }
      lc++;
    }
  }
  newsText = newsText.replace(/^\s*[\r\n]/gm, "");
  memoText = memoText.replace(/^\s*[\r\n]/gm, "");

  // create data object for json
  let clone = Object.assign(Object.create(Object.getPrototypeOf(mag)), mag);
  clone.nodes = [];
  for (var cc in articles) {
    clone.nodes.push(articles[cc]);
  }
  var newsJson = JSON.stringify(clone);

  //var text = "zipファイルに含める文字列\nテキストです";
  zip.file("newsExportJson.txt", newsJson);
  zip.file("news.txt", newsText);
  zip.file("memo.txt", memoText);
  zip.file("picDict.txt", picsText);

  // generate download event
  zip.generateAsync({ type: "blob" }).then(function (content) {
    //BlobからオブジェクトURLを作成する
    const url = (window.URL || window.webkitURL).createObjectURL(content);
    //ダウンロード用にリンクを作成する
    const download = document.createElement("a");
    //リンク先に上記で生成したURLを指定する
    download.href = url;
    //download属性にファイル名を指定する
    download.download = mag.date + ".zip";
    //作成したリンクをクリックしてダウンロードを実行する
    download.click();
    //createObjectURLで作成したオブジェクトURLを開放する
    (window.URL || window.webkitURL).revokeObjectURL(url);
  });
}
