<template>
  <div class="container-fluid">
    <button @click="back" class="btn bg-gradient-light">Back</button>
    <div class="row">
      <h2>{{ newsTitle }}</h2>
      <P>total letters = {{ getLen }}</P>
      <div class="col-lg-9 col-md-9 mb-md-0 mb-0">
        <form role="form" class="text-start">
          <input
            type="date"
            v-model="date"
            :min="new Date().toISOString().split('T')[0]"
            onkeydown="return false"
            @change="updateDate"
          />
          <br />
        </form>

        <div class="ztable-responsive">
          <table class="align-items-center news-table">
            <thead>
              <tr>
                <th>#</th>
                <th
                  class="news-text text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  News Title
                </th>
                <th>len</th>
                <th
                  v-on:ondrop="dropImg"
                  class="news-pic text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Title Pictures
                </th>
                <th>X</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oo, index) in newsList" :key="index">
                <td class="news-idx" @click="edit(index)">
                  {{ index }}
                </td>
                <td class="news-text" @click="edit(index)">
                  <span v-if="oo.lines[0]">{{ oo.lines[0].text }} </span>
                </td>
                <td>{{ getNodeLen(oo.text) }}</td>
                <td class="news-pic" :id="picId(index)">
                  <div v-if="oo.lines[0]">
                    <img
                      :src="getImage(oo.lines[0].pic)"
                      style="max-height: 90px"
                    />
                  </div>
                </td>
                <td>
                  <button
                    btn
                    @click="delNewsContent(index)"
                    style="background-color: red"
                  >
                    X
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="text-left">
        <vsud-button
          class="my-2 mb-1"
          variant="gradient"
          color="success"
          @click="newContent"
        >
          Add News Content / 記事を追加
        </vsud-button>
      </div>
    </div>
  </div>
</template>
<style>
.memo {
  min-height: 80px;
  width: 100%;
}
#drop-zone {
  background-color: #327767;
  margin: 4px;
  padding: 4px;
  color: #fff;
}
.drop-over {
  background-color: #afffc0;
}
.news-table {
  margin-top: 10px;
  border: solid 1px #aaa;
  width: 100%;
}

.news-table td:before {
  min-height: 20px !important;
}
.news-table .news-idx {
  border: solid 1px #aaa;
  width: 24px;
}
.news-table .news-text {
  width: 70%;
  min-height: 14px !important;
}

.news-table td {
  border: solid 1px #aaa;
}

.news-table .news-pic {
  min-width: 90px;
  min-height: 64px;
  padding: 0px;
  margin: 0px;
}

.news-pic {
  max-width: 160px !important;
  max-height: 60px !important;
}
</style>
<script>
//import VsudInput from "@/components/VsudInput.vue";
//import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
//import VueImageBase64 from "vuejs-image-base64";
import {
  onNewsMemoUpdate,
  onNewsDateUpdate,
  onFileCreate,
  onDeleteImage,
  onDeleteContent,
} from "../Repository";

export default {
  name: "dashboard-default",
  components: {
    //   VsudInput,
    //  VsudSwitch,
    VsudButton,
    //VueImageBase64,
  },

  data() {
    return {
      date: "",
      memo: "",
      images: { data: [] },
      errors: [],
      ctx: [{ text: "text", picId: 0, pic: "" }],
      pictures: [],
    };
  },
  computed: {
    getMemo() {
      return this.$store.state.curNews.memo;
    },
    getImages() {
      console.log(this.$store.state.curNews);
      console.log(this.$store.state.curNews.images);
      return this.$store.state.curNews.images;
    },
    newsTitle() {
      return "Title: " + this.$store.state.curNews.date;
    },
    newsList() {
      return this.$store.state.curNews.nodes;
    },
    getLen() {
      var cnt = 0;
      for (var nn in this.$store.state.curNews.nodes) {
        cnt += this.$store.state.curNews.nodes[nn].text.length;
      }
      return cnt;
    },
  },
  mounted() {
    this.memo = this.$store.state.curNews.memo;
    if (null == this.$store.state.curNews) this.$router.push("/news");
  },
  methods: {
    getNodeLen(text) {
      return text.length;
    },
    isImage(image) {
      console.log(image);
      if (image.deleted) {
        return false;
      }
      return true;
    },
    delNewsImage(id) {
      if (id < 0) return;
      if ([] == this.$store.state.curNews.images) return null;
      if (this.$store.state.curNews.images.length <= id) return null;
      if (confirm("Are you sure deleting this image? (id=" + id + ")"))
        onDeleteImage(id);
      //this.$store.commit("onNewsDelImage", id);
    },
    delNewsContent(id) {
      if (id < 0) return;
      if ([] == this.$store.state.curNews.images) return null;
      if (this.$store.state.curNews.images.length <= id) return null;
      if (confirm("Are you sure deleting this content? (id=" + id + ")"))
        onDeleteContent(id);
      //this.$store.commit("onNewsDelImage", id);
    },

    getImage(id) {
      if (id < 0) return null;
      if ([] == this.$store.state.curNews.images) return null;
      if (this.$store.state.curNews.images.length <= id) return null;
      return this.$store.state.curNews.images[id].url;
    },
    edit(idx) {
      this.$store.commit("onNodeSelect", idx);
      this.$router.push("/content-edit");
    },
    updateMemo() {
      onNewsMemoUpdate(this.memo);
    },

    updateDate() {
      //onNewsUpdate("onNewsUpdate", news);
      onNewsDateUpdate(this.date);
    },
    back() {
      this.$router.push("/news");
    },
    newContent: function () {
      var node = this.$store.dispatch("createContent");
      console.log(node);
      this.$router.push("/content-edit");
    },
    imgId(num) {
      return "img" + num.toString();
    },
    zoneId(num) {
      return "zone" + num.toString();
    },

    picId(num) {
      return "pic" + num.toString();
    },
    checkDrop: function (ev) {
      ev.preventDefault();
    },
    DGstart: function (ev) {
      console.log("dropStart");
      //      console.log(ev.target.id);
      ev.dataTransfer.setData("text/plain", ev.target.id);
    },
    DGdropEnd: function (ev) {
      ev.preventDefault();

      console.log("dropEnd");
      //     console.log(ev);
      //     console.log(ev.target.id);
    },
    DGover: function (ev) {
      ev.preventDefault();
      var tag = document.getElementById(ev.target.id);
      tag.classList.add("drop-over");
    },
    DGleave: function (ev) {
      ev.preventDefault();
      var tag = document.getElementById(ev.target.id);
      tag.classList.remove("drop-over");
    },
    DGdrop: function (ev) {
      ev.preventDefault();

      console.log("drop -----------");
      console.log(ev);
      console.log(ev.target.id);
      var zone = parseInt(ev.target.id.split("zone")[1]);
      let img = ev.dataTransfer.getData("text/plain");
      let imgIdx = parseInt(img.split("img")[1]);
      this.ctx[zone].picId = imgIdx;
      this.ctx[zone].pic = this.images.data[imgIdx];
      console.log(img.split("img"));
    },

    dropImg: function (ev) {
      ev.preventDefault();
      console.log("dropped ${ev}");
      console.log(ev.target.id);
    },
    dump: function () {
      console.log("dump");
      for (var aa in this.ctx) console.log(this.ctx[aa]);
    },
    descUpdate: function () {
      // this.title = document.getElementById("title").value;
      var arr = document.getElementById("desc").value.split(/\r\n|\n/);
      this.ctx = [];
      for (var tx in arr) {
        if (arr[tx].length > 2) this.ctx.push({ text: arr[tx], pic: "" });
      }
    },
    handleChange: function (data) {
      console.log(data);
      if (data.result) {
        onFileCreate(data, this.$store.state.curNews.id);
      } else {
        this.errors = data.messages;
      }
    },
  },
};
</script>
