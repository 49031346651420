<template>
  <div class="py-0 container-fluid">
    <div class="col-lg-6 col-7">
      <h4>Magazines / 記事パック一覧</h4>
    </div>

    <div class="row">
      <div class="row my-4">
        <div class="col-lg-12col-md-12 mb-md-0 mb-4">
          <news-card-v2
            :page="getPage()"
            :limit="getLimit()"
            :max="getMax()"
            v-bind:counter="render"
          />
          <button v-if="isPrevPage" btn @click="prevPage()">prev</button>
          <button v-if="isNextPage" btn @click="nextPage()">next</button>
        </div>
      </div>
    </div>
    <a
      class="btn bg-gradient-success mt-1"
      href="#"
      type="button"
      @click="createMag"
      >Create News</a
    >
  </div>
</template>
<script>
import NewsCardV2 from "./components/NewsCardV2.vue";
//import { Magazine } from "../newsDataV2.js";
import {
  onMagazineList,
  onMagazineNextList,
  onMagazinePrevList,
  onMagazineCreate,
  //  onArticleList,
  //  onArticleUpdate,
  //  onMagazineUpdate,
} from "../RepositoryV2";

export default {
  name: "",
  components: { NewsCardV2 },
  data() {
    return {
      render: 0,
    };
  },
  computed: {
    isNextPage() {
      return this.$store.state.mPage < this.$store.state.mPageMax;
    },
    isPrevPage() {
      return this.$store.state.mPage > 0;
    },
  },
  updated() {
    if (this.$store.state.magazines.length > this.$store.state.mPageMax)
      this.isPrevNext = true;
    console.log(this.$store.state.magazines.length);
  },
  mounted() {
    console.log("magazines mounted! ----------------------------------");
    onMagazineList();
    this.render++;
    //this.$store.dispatch("loadNews");
  },
  methods: {
    getPage() {
      return this.$store.state.mPage;
    },
    getMax() {
      return this.$store.state.mPageMax;
    },
    getLimit() {
      return this.$store.state.mPageLimit;
    },

    prevPage() {
      if (this.$store.state.mPage > 0) {
        //this.$store.commit("onMagazinesPrevPage");
        //this.isPrevPage = true;
        console.log("prevPage");
        onMagazinePrevList();
      }
    },
    nextPage() {
      if (this.$store.state.mPage < this.$store.state.mPageMax) {
        //this.$store.commit("onMagazinesNextPage");
        console.log("nextPage");
        onMagazineNextList();
      }
    },
    createMag: function () {
      onMagazineCreate().then((res) => {
        console.log(res);
        //this.$router.push("/magazine-edit");
      });
    },
    /*
    convert: function () {
      function sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }

      for (var mm in this.$store.state.magazines) {
        this.$store.state.magazines[mm].checkData();
        this.$store.commit("onMagazineSelect", mm);
        onArticleList(this.$store.state.magazines[mm].nodes).then(() => {
          for (var aa in this.$store.state.articles) {
            this.$store.state.articles[aa].checkData();
            onArticleUpdate(this.$store.state.articles[aa]);
          }
          onMagazineUpdate(this.$store.state.magazines[mm]);
          sleep(2500);
        });
        console.log(this.$store.state.magazines[mm]);
        if (mm > 0) break;
      */
  },
};
</script>
