<template>
  <div class="card">
    <div class="card-body px-0 pb-2">
      <p>{{ page }}/{{ max }}</p>
      <div :key="counter" class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Issue/号</th>
              <th>Articles</th>
              <th>AQI</th>
              <th>Weather</th>
              <th>Market</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(oo, index) in list" :key="oo.id">
              <td @click="edit(index)" :title="oo.id">
                {{ getIdx(index) }}
              </td>
              <td @click="edit(index)" :title="oo.id">
                {{ oo.date }}
              </td>
              <td>
                {{ oo.nodes.length }}
              </td>
              <td>
                {{ getAqi(oo) }}
              </td>
              <td>
                {{ getWeather(oo) }}
              </td>
              <td>
                {{ getMarket(oo) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "news-card",
  props: ["counter", "max", "limit", "page"],
  data() {
    return {
      newsList: [],
    };
  },
  methods: {
    getIdx(idx) {
      return parseInt(this.page) * parseInt(this.limit) + idx;
    },
    getAqi(obj) {
      var str = "";
      if (obj.Aqi.length > 2) {
        str = obj.Aqi[0].id + ":" + obj.Aqi[0].aqi;
        str = str + ", Da Nang :" + obj.Aqi[1].aqi;
        str = str + ", HCMC :" + obj.Aqi[2].aqi;
      }
      return str;
    },
    getWeather(obj) {
      var str = "";
      if (obj.Weather.length > 2) {
        str = "Ha Noi:" + ":" + obj.Weather[0].max;
        str = str + ", Da Nang :" + obj.Weather[1].max;
        str = str + ", HCMC :" + obj.Weather[2].max;
      }
      return str;
    },
    getMarket(obj) {
      var str = "";
      for (var mm in obj.Market) {
        str = str + obj.Market[mm].id + ":" + obj.Market[mm].val + "  ,";
      }
      return str;
    },

    edit(id) {
      this.$store.commit("onMagazineSelect", id);
      this.$router.push("/magazine-edit");
    },
    /*
    getImgLen(oo) {
      var cnt = 0;
      for (var nn in oo.nodes) {
        cnt += oo.nodes[nn].images.length;
      }
      return cnt;
    },
    getLen(oo) {
      var cnt = 0;
      for (var nn in oo.nodes) {
        cnt += oo.nodes[nn].text.length;
      }
      return cnt;
    },
  */
  },
  computed: {
    list() {
      return this.$store.state.magazines;
    },
  },
  components: {},
  mounted() {
    setTooltip();
  },
};
</script>
