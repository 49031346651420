import { createStore } from "vuex";
import { NewsNode } from "../newsData.js";
import { Article } from "../newsDataV2.js";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { onNewsCreate } from "../Repository";
import { onArticleCreate } from "../RepositoryV2.js";
//import { extractIdentifiers } from "@vue/compiler-core";

//import { static } from "vue";

export default createStore({
  state: {
    // app auth
    user: null,
    token: "",
    isAuth: false,
    email: "",

    // magazines
    mPage: 0, // magazines page
    mOfs: 0,
    mPageLimit: 20,
    mPageMax: 0,
    numMag: 0,
    pages: [],
    magazineSnapshot: null,

    // newsEdit  v1
    newsAry: [],

    // v2 (curNews = current magazine )
    magazines: [],
    articles: [],
    curArId: null, //article id
    curAr: null, //article
    curNewsId: 0,
    curNews: null,

    //
    curNodeId: 0,
    curEdit: null,
    curNode: null,
    editMode: "", //create or update
    //images: [],
    // framework
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    isRoot: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    //
  },
  getters: {
    getUser: (state) => () => {
      return state.mail;
    },
    getArticle: (state, idx) => () => {
      //console.log(state.newsAry);
      //console.log(idx);
      if (state.newsAry.length < idx) return state.newsAry[idx];

      console.log("Article index overflow");
      return null;
    },
  },
  actions: {
    checkLogin({ commit }) {
      console.log("checkLogin");
      commit("checkSessionToken");
    },
    auth({ commit }, user) {
      console.log(user);
      commit("onAuthChanged", user.mail, user.token);
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    createNews({ commit, getters }) {
      // var news = new NewsObj();
      console.log("create news");
      var news = onNewsCreate(getters.getUser);
      commit("onNewsAdd", news);
      commit("onNewsSelect", news.idx);
      return news;
    },

    // v1
    createContent({ commit }) {
      var node = new NewsNode();
      commit("onNodeAdd", node);
      //commit("onNodeSelect", store.state..nodes.length - 1);
      return node;
    },
    createArticle({ commit, getters }) {
      //v2
      console.log("create article");
      var ar = new Article();
      onArticleCreate(getters.getUser);
      ar.magID = commit("onArticleAdd", ar);
      commit("onArticleSelect", ar.idx);
      return ar;
    },

    updateNews({ commit }, news) {
      commit("onNewsUpdateNews", news);
    },
    loadNews({ commit }) {
      console.log("loadNews");
      commit("onNewsLoad");
    },
  },

  mutations: {
    onMagazinePageMax(state, num) {
      state.mPageMax = num;
    },
    onMagazinesNum(state, num) {
      state.numMag = num;
    },
    onMagazinesPrevPage(state) {
      state.mPage--;
    },
    onMagazinesNextPage(state) {
      state.mPage++;
    },
    checkSessionToken(state) {
      console.log(state.token);
      console.log("checkSessionToken");
    },
    onAuthChanged(state, mail, token) {
      state.mail = mail;
      state.token = token;
      state.isAuth = true;
      //onAuthByEmail( mail, )
    },
    onLogout(state) {
      state.mail = "";
      state.token = "";
      state.isAuth = false;
      console.log("onAuthLogout");
    },

    // News

    onNewsReset(state) {
      state.curNewsId = 0;
      state.curNodeId = 0;
      state.curNews = null;
      state.curNode = null;
      state.curAr = null;
      state.curArId = 0;
      state.newsAry = [];
    },

    onNewsSelect(state, id) {
      console.log("select news " + id);
      state.curNewsId = id;
      state.curNews = state.newsAry[id];
      state.curNodeId = 0;
      state.curNode = null;
      state.curAr = null;
      state.curArId = 0;
    },
    onNewsAdd(state, news) {
      news.auther = state.mail;
      news.idx = state.newsAry.length;
      state.newsAry.push(news);
      console.log("add news, id=" + news.idx);
    },
    onNewsLoad(state, news) {
      state.newsAry.push(news);
    },
    onNewsWriter(state, name) {
      state.curNews.lastUpdater = name;
    },

    onNewsUpdate(state, id, news) {
      for (var idx in state.newsAry) {
        if (state.newsAry[idx].id == id) {
          state.newsAry[idx] = news;
          return;
        }
      }
    },
    onNewsDateUpdate(state, date) {
      state.curNews.date = date;
    },
    onNewsMemoUpdate(state, memo) {
      state.curNews.memo = memo;
    },

    // Image --------------
    onNewsAddImage(state, { url, name }) {
      return state.curNews.addImage(url, name);
    },
    // Image --------------
    onNewsDelImage(state, idx) {
      return state.curNews.delImage(idx);
    },
    onNewsDelContent(state, idx) {
      return state.curNews.delContent(idx);
    },

    // magazine -==============================================
    onMagazineLoad(state, mag) {
      state.magazines.push(mag);
    },
    onMagazineSnapshot(state, snapshot) {
      state.magazineSnapshot = snapshot;
    },

    onMagazineAdd(state, mag) {
      state.magazines.push(mag);
    },

    onMagazineDel(state, idx) {
      state.magazines.splice(idx, 1);
    },

    // article
    onMagazineReset(state) {
      state.magazines = [];
    },
    onMagazineSelect(state, id) {
      state.curNewsId = id;
      state.curNews = state.magazines[id];
      state.articles = [];
      state.curNodeId = 0;
      state.curNode = null;
    },
    onMagazineDateUpdate(state, date) {
      state.curNews.date = date;
    },
    onMagazineMemoUpdate(state, memo) {
      state.curNews.memo = memo;
    },

    onMagazineDelArticle(state, idx) {
      state.curNews.delArticle(idx);
      //state.magazines.splice(idx, 1);
    },

    onMagazineAddArticle(state, id) {
      state.curNews.addArticle(id);
    },

    // article --------------------------------------------------
    onArticleLoad(state, ar) {
      state.articles.push(ar);
      state.curAr = state.articles[state.curNodeId];
      state.curArId = state.articles.length - 1;
      return state.curNode;
    },

    onArticleDateUpdate(state, date) {
      state.curAr.date = date;
    },

    onArticleDeletedAt(state, idx) {
      state.articles[idx].deteledAt = new Date().toISOString();
    },

    onArticleDel(state, idx) {
      state.articles.splice(idx, 1);
    },

    // article
    onArticleReset(state) {
      state.articles = [];
    },

    // article
    onArticleEnableType(state, { idx, type }) {
      if (state.articles[idx].type.indexOf(type) >= 0) {
        return;
      } else {
        if (state.articles[idx].type == "") state.articles[idx].type = type;
        else state.articles[idx].type = state.articles[idx].type + "," + type;
      }
    },
    // article
    onArticleDisableType(state, { idx, type }) {
      var tts = state.articles[idx].type.split(",");
      var str = "";
      for (var xx in tts) {
        if (tts[xx] == type) continue;
        if (str == "") str = tts[xx];
        else str = str + "," + tts[xx];
      }
      state.articles[idx].type = str;
    },

    // article
    onArticleTypeToggle(state, { idx, type }) {
      var flg = state.articles[idx].type.indexOf(type) >= 0;
      if (!flg) {
        this.commit("onArticleEnableType", { idx, type });
      } else {
        this.commit("onArticleDisableType", { idx, type });
      }
    },

    // Image --------------
    onArticleDelImage(state, idx) {
      return state.curAr.delImage(idx);
    },
    // Image --------------
    onArticleAddImage(state, { url, name }) {
      return state.curAr.addImage(url, name);
    },

    // Node --------------------------------------------------------
    onNodeAdd(state, node) {
      let len = state.curNews.nodes.length;
      if (0 == len) {
        node.id = 0;
      } else {
        node.id = state.curNews.nodes[len - 1].id + 1;
      }
      state.curNodeId = node.id;
      state.curNode = node;
      state.curNews.nodes.push(node);
    },
    onNodePicUpdate(state, { lineIdx, pic }) {
      if (undefined === pic) {
        return;
      }
      state.curNode.lines[lineIdx].pic = pic;
    },

    onNodePoseUpdate(state, { idx, pose }) {
      state.curNode.lines[idx].mcAnim = pose;
    },

    onClearLines(state) {
      state.curNode.clearLines();
    },

    onLineUpdate(state, lines) {
      state.curNode.updateAllLines(lines);
    },
    onNodeUpdate(state, node) {
      state.curNode.update(node);
    },
    onNodeTextUpdate(state, txt) {
      state.curNode.text = txt;
    },
    onNodeMemoUpdate(state, txt) {
      state.curNode.memo = txt;
    },
    onNodeLineGet(state, idx) {
      return state.curNode.lines[idx];
    },
    onNodeLineSet(state, idx, line) {
      return (state.curNode.lines[idx] = line);
    },

    onNodeLineUpdate(state, idx, txt) {
      state.curNode.updateLine(idx, txt);
    },

    onNodeSelect(state, idx) {
      state.curNodeId = idx;
      state.curNode = state.curNews.nodes[idx];
      return state.curNode;
    },

    // Article  --------------------------------------------------------
    onArticleAdd(state, node) {
      let len = state.articles.length;
      if (0 == len) {
        node.idx = 0;
      } else {
        node.idx = state.articles[len - 1].idx + 1;
      }
      state.curArId = node.id;
      state.curAr = node;
      state.articles.push(node);
    },
    onArticlePicUpdate(state, { lineIdx, pic }) {
      if (undefined === pic) {
        return;
      }
      state.curAr.lines[lineIdx].pic = {
        name: state.curAr.images[pic].name,
        url: state.curAr.images[pic].url,
      };
    },
    onArticlePicDelete(state, lineIdx) {
      state.curAr.lines[lineIdx].pic = {
        name: "",
        url: "",
      };
    },

    onArticlePoseUpdate(state, { idx, pose }) {
      state.curAr.lines[idx].mcAnim = pose;
    },

    onArticleClearLines(state) {
      state.curAr.clearLines();
    },

    onArticleLineUpdate(state, lines) {
      state.curAr.updateAllLines(lines);
    },
    onArticleUpdate(state, node) {
      state.curAr.update(node);
    },
    onArticleTextUpdate(state, txt) {
      state.curAr.text = txt;
    },

    onArticleMemoUpdate(state, txt) {
      state.curAr.urls = txt;
    },

    onArticleLineGet(state, idx) {
      return state.curAr.lines[idx];
    },
    onArticleLineSet(state, idx, line) {
      return (state.curAr.lines[idx] = line);
    },

    onArticleineUpdate(state, idx, txt) {
      state.curAr.updateLine(idx, txt);
    },

    onArticleSelect(state, idx) {
      state.curArId = idx;
      state.curAr = state.articles[idx];
      console.log("onArticleSelect");
      //console.log(state.curAr);
      return state.curAr;
    },
    // UI
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
});
